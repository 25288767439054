export const imports = {
  'src/apps.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-apps" */ 'src/apps.mdx'
    ),
  'src/configuration.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-configuration" */ 'src/configuration.mdx'
    ),
  'src/content.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-content" */ 'src/content.mdx'
    ),
  'src/contenttypes.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-contenttypes" */ 'src/contenttypes.mdx'
    ),
  'src/dashboard.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-dashboard" */ 'src/dashboard.mdx'
    ),
  'src/events.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-events" */ 'src/events.mdx'
    ),
  'src/groups.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-groups" */ 'src/groups.mdx'
    ),
  'src/introduction.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-introduction" */ 'src/introduction.mdx'
    ),
  'src/layout.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-layout" */ 'src/layout.mdx'
    ),
  'src/levels.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-levels" */ 'src/levels.mdx'
    ),
  'src/locations.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-locations" */ 'src/locations.mdx'
    ),
  'src/players.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-players" */ 'src/players.mdx'
    ),
  'src/playlists.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-playlists" */ 'src/playlists.mdx'
    ),
  'src/profile.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-profile" */ 'src/profile.mdx'
    ),
  'src/signin.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-signin" */ 'src/signin.mdx'
    ),
  'src/summary.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-summary" */ 'src/summary.mdx'
    ),
  'src/users.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-users" */ 'src/users.mdx'
    ),
}
